import React, { useState } from "react";
import { Helmet } from "react-helmet";

import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ currentPath, title, children }) => {
  const [darkMode, setDarkMode] = useState(() => {
    if (typeof window !== "undefined") {
      return localStorage.getItem("mode") === "dark";
    }
    return false;
  });

  const toggleDarkMode = (mode) => {
    if (mode) {
      document.documentElement.className = "dark";
      localStorage.setItem("mode", "dark");
    } else {
      document.documentElement.className = "";
      localStorage.setItem("mode", "light");
    }
    setDarkMode(mode);
  };

  return (
    <main id="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${title} | Tinkertax`}</title>
        <link rel="canonical" href="https://tinkertax.com/" />
      </Helmet>
      <div className="dark:bg-black min-h-screen flex flex-col">
        <Header
          currentPath={currentPath}
          darkMode={darkMode}
          setDarkMode={toggleDarkMode}
        />
        <section className="pt-14 flex-grow">{children}</section>
        <Footer />
      </div>
    </main>
  );
};

export default Layout;
