import React, { useState } from "react";
import { Link } from "gatsby";

import logo from "../images/Logowhite-with-text.png";
import SideNav from "./SideNav";
import Menu from "../icons/menu.svg";
import Cross from "../icons/cross.svg";

const pages = [
  { label: "About", link: "/about/" },
  { label: "Blog", link: "/blog/" },
  { label: "Privacy", link: "/privacy/" },
  { label: "Contact", link: "/contact/" },
];

const navStyle = (currentPath, navPath) => {
  if (currentPath === navPath) {
    return "text-center px-2 text-white font-medium dark:text-gray-100";
  }
  return "text-center px-2 text-white dark:text-gray-200";
};

const Header = ({ currentPath, darkMode, setDarkMode }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header className="fixed top-0 w-screen dark:bg-black z-50">
      <div className="p-4 flex justify-between items-center bg-primary-500 dark:bg-black">
        <Link className="w-1/3" to="/">
          <img src={logo} alt="Tinkertax" width="150" />
        </Link>
        <div className="flex w-2/3">
          <div className="hidden md:flex w-full justify-end">
            {pages.map((page, index) => (
              <div
                className={navStyle(currentPath, page.link)}
                key={`page-${index}`}
              >
                <Link to={page.link}>{page.label}</Link>
              </div>
            ))}

            {/* <div className="flex">
              <span className="mr-2 text-primary-700 dark:text-gray-200">Dark Mode</span>
              <ToggleSwitch darkMode={darkMode} setDarkMode={setDarkMode} />
            </div> */}
          </div>

          <div className="flex md:hidden w-full justify-end">
            {!showMenu && (
              <span className="text-white dark:text-primary-400">
                <Menu onClick={() => setShowMenu(true)} />
              </span>
            )}
            {showMenu && (
              <span className="text-white dark:text-primary-400">
                <Cross onClick={() => setShowMenu(false)} />
              </span>
            )}
          </div>
        </div>
      </div>
      <SideNav
        pages={pages}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
      />
    </header>
  );
};

export default Header;
