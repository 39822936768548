import React from "react";
import { Link } from "gatsby";
import ToggleSwitch from "./ToggleSwitch";
import logo from "../images/Logo(color)-with-text.png";

const SideNav = ({ pages, showMenu, darkMode, setDarkMode, setShowMenu }) => {
  return (
    <div>
      <aside
        className={`fixed top-0 p-4 min-h-screen w-4/5 dark:text-gray-200 transform ease-in-out duration-300 bg-white dark:bg-primary-900 shadow-xl z-20 ${
          showMenu ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <img src={logo} alt="Tinkertax" width="120" />
        {pages.map((page, index) => (
          <div className="py-2" key={`page-${index}`}>
            <Link to={page.link}>{page.label}</Link>
          </div>
        ))}
        {/* <div className="flex py-2">
          <span className="mr-2">Dark Mode</span>
          <ToggleSwitch darkMode={darkMode} setDarkMode={setDarkMode} />
        </div> */}
      </aside>
      {showMenu && (
        <div
          className="fixed top-0 min-h-screen w-screen bg-black z-10 opacity-75"
          onClick={() => setShowMenu(false)}
          onKeyDown={() => setShowMenu(false)}
          role="dialog"
        ></div>
      )}
    </div>
  );
};

export default SideNav;
